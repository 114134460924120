import { Suspense, useEffect } from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { FullLoader } from 'ui'
import { AppRoutes } from 'pages/routes'
import { Modals } from 'features/modal/modals'
import Toasts from 'features/toasts/toasts'
import { TonConnectUIProvider } from '@tonconnect/ui-react'

import 'react-loading-skeleton/dist/skeleton.css'

const App = () => {
    useEffect(() => {
        const updateAppHeight = () => {
            document.documentElement.style.setProperty(
                '--app-height',
                `${document.documentElement.clientHeight}px`
            )
        }

        updateAppHeight()
        window.addEventListener('resize', updateAppHeight)

        return () => {
            window.removeEventListener('resize', updateAppHeight)
        }
    }, [])

    return (
        <TonConnectUIProvider manifestUrl="https://www.prodiapp.ru/tonconnect-manifest.json">
            <SkeletonTheme
                baseColor="rgba(0, 0, 0, .2)"
                highlightColor="rgba(0, 0, 0, .2)"
                inline
            >
                <Suspense fallback={<FullLoader />}>
                    <HelmetProvider>
                        <Helmet>
                            <title>Egg Meme!</title>
                        </Helmet>
                        <AppRoutes />
                        <Modals />
                        <Toasts />
                    </HelmetProvider>
                </Suspense>
            </SkeletonTheme>
        </TonConnectUIProvider>
    )
}

export default App
