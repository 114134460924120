import { Table } from 'features/holders/table'
import { Invite } from 'features/holders/invite'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { getHolders } from 'models/holders/actions'

import c from './assets/sass/page.module.scss'

const HoldersPage = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getHolders())
    }, [dispatch])

    return (
        <div className={c.component}>
            <div className={c.top}>
                <span className={c.title}>EGG Holders</span>
                <span className={c.subtitle}>Leaders of earning EGGS</span>
            </div>
            <div className={c.middle}>
                <Table />
            </div>
            <div className={c.bottom}>
                <Invite />
            </div>
        </div>
    )
}

export default HoldersPage
