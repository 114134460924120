import cn from 'classnames'
import { useWindowActions } from 'models/window'
import { useSelector } from 'react-redux'
import { userSelector } from 'models/user/selectors'
import { useState } from 'react'
import { userApi } from 'api/user'
import {toast} from "react-toastify";

import { ReactComponent as CloseIcon } from './assets/images/close.svg'

import c from './assets/sass/profileModal.module.scss'

export const ProfileModal = () => {
    const { closeWindow } = useWindowActions()
    const [isConfirmed, setIsConfirmed] = useState(false)
    const [username, setUsername] = useState('')
    const [profileLink, setProfileLink] = useState('')
    const user = useSelector(userSelector)

    const handleClickClose = () => {
        closeWindow()
    }

    const handleButton = async () => {
        if (!username.trim()) {
            toast.error('Username cannot be empty')
            return
        }

        if (!profileLink.trim()) {
            toast.error('Profile link cannot be empty')
            return
        }

        try {
            await userApi.setProfile(user.telegramId, username, profileLink)
            toast.success('Profile updated successfully')
            setIsConfirmed(true)
        } catch (error) {
            toast.error('Error updating profile')
        }
    }

    return (
        <div className={c.component}>
            <button className={c.close} onClick={handleClickClose}>
                <CloseIcon />
            </button>
            <div className={c.top}>
                <span className={c.title}>Edit profile</span>
                <span className={c.subtitle}>
                    You can set <strong>username</strong> and <strong>any link</strong> to your account
                </span>
            </div>
            <div className={c.form}>
                <input
                    className={c.field}
                    placeholder={user.name || 'Username'}
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <input
                    className={c.field}
                    placeholder={user.profile || 'Link to some page'}
                    type="text"
                    value={profileLink}
                    onChange={(e) => setProfileLink(e.target.value)}
                />
                <button
                    className={cn(c.button, { [c.confirmed]: isConfirmed })}
                    onClick={handleButton}
                    disabled={isConfirmed}
                >
                    {isConfirmed ? 'Confirmed!' : 'Confirm'}
                </button>
            </div>
        </div>
    )
}
