import { useWindowActions } from 'models/window'

import { ReactComponent as CloseIcon } from './assets/images/close.svg'

import c from './assets/sass/faqModal.module.scss'

export const FaqModal = () => {
    const { closeWindow } = useWindowActions()

    const handleClickClose = () => {
        closeWindow()
    }

    return (
        <div className={c.component}>
            <button className={c.close} onClick={handleClickClose}>
                <CloseIcon />
            </button>
            <div className={c.top}>
                <span className={c.title}>FAQ</span>
                <span className={c.subtitle}>Questions and answers</span>
            </div>
            <ul className={c.list}>
                <li className={c.item}>
                    <span className={c.itemTitle}>How to claim airdrop?</span>
                    <p className={c.text}>
                        Airdrop claiming will be active from 12th September
                    </p>
                </li>
                <li className={c.item}>
                    <span className={c.itemTitle}>What is staking here?</span>
                    <p className={c.text}>
                        You will be able to stake your EGGS ($EGG). To earn
                        rewards in EGG. Staking features to be live very soon
                    </p>
                </li>
                <li className={c.item}>
                    <span className={c.itemTitle}>
                        How can I claim private sale share?
                    </span>
                    <p className={c.text}>
                        They will be active for claiming on 12th September on
                        this website.
                    </p>
                </li>
            </ul>
            <button className={c.button}>Still have a question?</button>
        </div>
    )
}
