import { Counter } from 'features/friends/counter'
import { Table } from 'features/friends/table'
import { Invite } from 'features/friends/invite'
import { Empty } from 'features/friends/empty'
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getFriends} from "models/friends";
import {userSelector} from "models/user/selectors";
import {friendsSelector} from "models/friends/selectors";

import c from './assets/sass/page.module.scss'

const FriendsPage = () => {
    const dispatch = useDispatch()
    const user = useSelector(userSelector)
    const friends = useSelector(friendsSelector)

    useEffect(() => {
        dispatch(getFriends(user.telegramId))
    }, [dispatch, user.telegramId]);

    return (
        <div className={c.component}>
            <Counter amount={user.referralCount} bonuses={user.earnedEggs} />
            <div className={c.middle}>{(user.referralCount > 0) ? <Table friends={friends} /> : <Empty />}</div>
            <div className={c.bottom}>
                <Invite />
            </div>
        </div>
    )
}

export default FriendsPage
