import {
    USER_LOAD,
    USER_SUCCESS,
    USER_FAIL,
    BALANCE_LOAD,
    BALANCE_SUCCESS,
    BALANCE_FAIL,
    CREATE_LOAD,
    CREATE_SUCCESS,
    CREATE_FAIL,
    WITHDRAW_LOAD,
    WITHDRAW_SUCCESS,
    WITHDRAW_FAIL,
} from './constants'
import { userApi } from 'api/user'

export const getUser = (telegramId) => (dispatch) => {
    dispatch({ type: USER_LOAD })

    userApi
        .getUser(telegramId)
        .then((response) =>
            dispatch({
                type: USER_SUCCESS,
                payload: response.data,
            })
        )
        .catch(() => {
            dispatch({ type: USER_FAIL })
        })
}

export const getBalance = (accountId) => (dispatch) => {
    dispatch({ type: BALANCE_LOAD })

    userApi
        .getBalance(accountId)
        .then((response) =>
            dispatch({
                type: BALANCE_SUCCESS,
                payload: response.data.balance,
            })
        )
        .catch(() => {
            dispatch({ type: BALANCE_FAIL })
        })
}

export const createUser = (userData) => (dispatch) => {
    dispatch({ type: CREATE_LOAD })

    userApi
        .createUser(userData)
        .then((response) =>
            dispatch({
                type: CREATE_SUCCESS,
                payload: response.data,
            })
        )
        .catch(() => {
            dispatch({ type: CREATE_FAIL })
        })
}

export const requestWithdraw = () => (dispatch) => {
    dispatch({ type: WITHDRAW_LOAD })

    userApi
        .createUser()
        .then((response) =>
            dispatch({
                type: WITHDRAW_SUCCESS,
                payload: response.data,
            })
        )
        .catch(() => {
            dispatch({ type: WITHDRAW_FAIL })
        })
}
