import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { Page } from 'ui'
import { ContentLoader } from 'ui'

export const Layout = () => {
    return (
        <>
            <Page>
                <Suspense fallback={<ContentLoader />}>
                    <Outlet />
                </Suspense>
            </Page>
        </>
    )
}
