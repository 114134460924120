import { LOAD, SUCCESS, FAIL } from './constants'
import { holdersApi } from 'api/holders'

export const getHolders = () => (dispatch) => {
    dispatch({ type: LOAD })

    holdersApi
        .getHolders()
        .then((response) =>
            dispatch({
                type: SUCCESS,
                payload: response.data,
            })
        )
        .catch(() => {
            dispatch({ type: FAIL })
        })
}
