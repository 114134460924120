import c from './assets/sass/egg.module.scss'

export const Egg = () => {
    return (
        <div className={c.component}>
            <div className={c.platform}>
                <div className={c.egg} />
            </div>
        </div>
    )
}
