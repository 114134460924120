import { useEffect, useRef } from 'react'
import { useLatest } from './use-latest'

export const useOutsideClick = (handler, isEnabled = true) => {
    const elementRef = useRef(null)
    const latestHandler = useLatest(handler)

    useEffect(() => {
        if (!isEnabled) {
            return
        }

        const handleClick = (e) => {
            if (!elementRef.current) {
                return
            }

            if (!elementRef.current.contains(e.target)) {
                const path =
                    e.path || (e.composedPath && e.composedPath()) || []

                if (path.indexOf(elementRef.current) === -1) {
                    latestHandler.current()
                }
            }
        }

        setTimeout(() => {
            document.addEventListener('click', handleClick)
        }, 0)

        return () => {
            document.removeEventListener('click', handleClick)
        }
    }, [elementRef, latestHandler, isEnabled])

    return elementRef
}
