import { useState, useRef } from 'react'
import cn from 'classnames'
import { useWindowActions } from 'models/window'

import { ReactComponent as CloseIcon } from './assets/images/close.svg'

import c from './assets/sass/inviteModal.module.scss'

export const InviteModal = () => {
    const { closeWindow } = useWindowActions()
    const [isCopied, setIsCopied] = useState(false)
    const inviteLinkRef = useRef(null)
    const telegramData = window.Telegram.WebApp

    const handleClickClose = () => {
        closeWindow()
    }

    const handleCopy = () => {
        const link = inviteLinkRef.current.textContent
        navigator.clipboard.writeText(link).then(() => {
            setIsCopied(true)
        })
    }

    return (
        <div className={c.component}>
            <button className={c.close} onClick={handleClickClose}>
                <CloseIcon />
            </button>
            <div className={c.top}>
                <span className={c.title}>Invite link</span>
                <span className={c.subtitle}>
                    Send this link to your friend for more eggs
                </span>
            </div>
            <div className={c.form}>
                <div className={c.field} ref={inviteLinkRef}>
                    <span>
                        {`https://t.me/TestEggggerBot/TestEgggMememe?startapp=${telegramData.initDataUnsafe?.user?.id}`}
                    </span>
                </div>
                <button
                    className={cn(c.button, { [c.copied]: isCopied })}
                    onClick={handleCopy}
                >
                    {isCopied ? 'Copied!' : 'Copy'}
                </button>
            </div>
        </div>
    )
}
