import { Suspense } from 'react'
import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import { ContentLoader } from 'ui'
import { useComponent } from './use-component'

import c from './modal.module.scss'

export const Modal = ({ opened, children }) => {
    const { componentRef, contentRef, handleEnter, handleExited } =
        useComponent(opened)

    return createPortal(
        <CSSTransition
            classNames={{
                enter: c.enter,
                enterActive: c.enterActive,
                exit: c.exit,
                exitActive: c.exitActive,
            }}
            timeout={200}
            in={opened}
            mountOnEnter
            unmountOnExit
            onEnter={handleEnter}
            onExited={handleExited}
        >
            <div
                ref={componentRef}
                className={c.component}
                role="dialog"
                aria-modal
            >
                <div ref={contentRef} className={c.content}>
                    <Suspense fallback={<ContentLoader />}>{children}</Suspense>
                </div>
            </div>
        </CSSTransition>,
        document.getElementById('root')
    )
}
