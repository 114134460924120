import axios from 'axios'

const contractAddress =
    '0:678dcf65e4f2741e753a6ae12caa9a3d37658c9e27d2b853026c02a2a536f32a'

export const holdersApi = {
    getHolders: () =>
        axios.get(
            `https://tonapi.io/v2/jettons/${encodeURIComponent(contractAddress)}/holders`,
            {
                params: {
                    limit: 1000,
                    offset: 0,
                },
            }
        ),
}
