import { TonConnectButton, useTonAddress } from '@tonconnect/ui-react'
import cn from 'classnames'

import c from './assets/sass/wallet.module.scss'

export const Wallet = () => {
    const address = useTonAddress()

    return (
        <div className={cn(c.component, { [c.notConnected]: !address })}>
            <div className={c.field}>
                <span>{address || 'Not connected'}</span>
            </div>
            {!address && (
                <button className={c.connect}>
                    <span className={c.buttonTitle}>Connect</span>
                    <TonConnectButton />
                </button>
            )}
        </div>
    )
}
