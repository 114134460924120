import { useEffect, useState } from 'react'

import c from './assets/sass/table.module.scss'

export const Table = () => {
    const [maxRefHeight, setMaxRefHeight] = useState(0)

    useEffect(() => {
        const height = document.documentElement.clientHeight - 250
        setMaxRefHeight(height)

        const handleResize = () => {
            const height = document.documentElement.clientHeight - 250
            setMaxRefHeight(height)
        }

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <div className={c.component}>
            <div
                className={c.wrapper}
                style={{ maxHeight: `${maxRefHeight}px` }}
            >
                <ul className={c.list}>
                    <li className={c.item}>
                        <span className={c.name}>LimaG</span>
                        <span className={c.users}>182</span>
                        <span className={c.eggs}>+7 189</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>AlphaX</span>
                        <span className={c.users}>245</span>
                        <span className={c.eggs}>+5 320</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>BetaQ</span>
                        <span className={c.users}>132</span>
                        <span className={c.eggs}>+6 050</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>GammaZ</span>
                        <span className={c.users}>198</span>
                        <span className={c.eggs}>+4 732</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>DeltaW</span>
                        <span className={c.users}>167</span>
                        <span className={c.eggs}>+8 145</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>EpsilonY</span>
                        <span className={c.users}>203</span>
                        <span className={c.eggs}>+7 297</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>ZetaR</span>
                        <span className={c.users}>154</span>
                        <span className={c.eggs}>+6 089</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>EtaS</span>
                        <span className={c.users}>189</span>
                        <span className={c.eggs}>+5 500</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>ThetaT</span>
                        <span className={c.users}>175</span>
                        <span className={c.eggs}>+7 804</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>IotaU</span>
                        <span className={c.users}>213</span>
                        <span className={c.eggs}>+4 676</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>KappaV</span>
                        <span className={c.users}>192</span>
                        <span className={c.eggs}>+8 239</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>LambdaM</span>
                        <span className={c.users}>208</span>
                        <span className={c.eggs}>+6 915</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>MuN</span>
                        <span className={c.users}>173</span>
                        <span className={c.eggs}>+5 322</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>NuO</span>
                        <span className={c.users}>184</span>
                        <span className={c.eggs}>+7 188</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>XiP</span>
                        <span className={c.users}>199</span>
                        <span className={c.eggs}>+6 467</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>OmicronQ</span>
                        <span className={c.users}>157</span>
                        <span className={c.eggs}>+5 873</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>PiR</span>
                        <span className={c.users}>211</span>
                        <span className={c.eggs}>+7 129</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>RhoS</span>
                        <span className={c.users}>178</span>
                        <span className={c.eggs}>+4 981</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>SigmaT</span>
                        <span className={c.users}>223</span>
                        <span className={c.eggs}>+6 752</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>TauU</span>
                        <span className={c.users}>169</span>
                        <span className={c.eggs}>+8 034</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>UpsilonV</span>
                        <span className={c.users}>194</span>
                        <span className={c.eggs}>+5 608</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>PhiW</span>
                        <span className={c.users}>188</span>
                        <span className={c.eggs}>+7 391</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>ChiX</span>
                        <span className={c.users}>205</span>
                        <span className={c.eggs}>+6 210</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>PsiY</span>
                        <span className={c.users}>201</span>
                        <span className={c.eggs}>+5 873</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>OmegaZ</span>
                        <span className={c.users}>177</span>
                        <span className={c.eggs}>+7 291</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>AphaA</span>
                        <span className={c.users}>226</span>
                        <span className={c.eggs}>+6 123</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>BetaB</span>
                        <span className={c.users}>165</span>
                        <span className={c.eggs}>+7 432</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>GammaC</span>
                        <span className={c.users}>187</span>
                        <span className={c.eggs}>+6 579</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>DeltaD</span>
                        <span className={c.users}>199</span>
                        <span className={c.eggs}>+5 434</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>EpsilonE</span>
                        <span className={c.users}>180</span>
                        <span className={c.eggs}>+8 051</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>ZetaF</span>
                        <span className={c.users}>191</span>
                        <span className={c.eggs}>+7 128</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>EtaG</span>
                        <span className={c.users}>166</span>
                        <span className={c.eggs}>+6 785</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>ThetaH</span>
                        <span className={c.users}>208</span>
                        <span className={c.eggs}>+5 964</span>
                    </li>
                    <li className={c.item}>
                        <span className={c.name}>IotaI</span>
                        <span className={c.users}>199</span>
                        <span className={c.eggs}>+7 303</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}
