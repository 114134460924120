import { CLOSE_WINDOW, OPEN_WINDOW } from './constants'

export const openWindow = (name, props = null) => ({
    type: OPEN_WINDOW,
    payload: { name, props },
})

export const closeWindow = () => ({
    type: CLOSE_WINDOW,
})
