import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import './_toastify.scss'

const Toasts = () => (
    <ToastContainer
        closeButton={false}
        newestOnTop
        autoClose={3000}
        theme="colored"
    />
)

export default Toasts
