import { useRef } from 'react'
import { lock, unlock } from 'tua-body-scroll-lock'
import { useWindowActions } from 'models/window'
import { useOutsideClick } from 'core/hooks'

export const useComponent = (opened) => {
    const { closeWindow } = useWindowActions()

    const componentRef = useRef()

    const contentRef = useOutsideClick(() => {
        closeWindow()
    }, opened)

    const handleEnter = () => {
        lock(componentRef.current, { reserveScrollBarGap: true })
    }

    const handleExited = () => {
        unlock(componentRef.current)
    }

    return {
        componentRef,
        contentRef,
        handleEnter,
        handleExited,
    }
}
