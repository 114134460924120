import { Record } from 'immutable'
import { CLOSE_WINDOW, OPEN_WINDOW } from './constants'

const OpenedWindowRecord = Record({
    name: null,
    props: null,
})

const reducer = (state = new OpenedWindowRecord(), action) => {
    const { type, payload } = action

    switch (type) {
        case OPEN_WINDOW:
            return state.set('name', payload.name).set('props', payload.props)

        case CLOSE_WINDOW:
            return state.set('name', null)

        default:
            return state
    }
}

export default reducer
