import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { windowSelectors } from 'models/window'
import { Modal } from './modal'
import { modalNames } from './constants/names'
import { FaqModal } from 'features/faq-modal'
import { SettingsModal } from 'features/settings-modal'
import { InviteModal } from 'features/invite-modal'
import {DisconnectModal} from "features/disconnect-modal";
import {WithdrawModal} from "features/withdraw-modal";
import {ProfileModal} from "features/profile-modal";

export const Modals = () => {
    const name = useSelector(windowSelectors.name)
    const props = useSelector(windowSelectors.props)

    const [template, setTemplate] = useState(null)

    useEffect(() => {
        if (name) {
            switch (name) {
                case modalNames.FAQ:
                    setTemplate(<FaqModal />)
                    break
                case modalNames.SETTINGS:
                    setTemplate(<SettingsModal />)
                    break
                case modalNames.INVITE:
                    setTemplate(<InviteModal />)
                    break
                case modalNames.DISCONNECT:
                    setTemplate(<DisconnectModal />)
                    break
                case modalNames.WITHDRAW:
                    setTemplate(<WithdrawModal />)
                    break
                case modalNames.PROFILE:
                    setTemplate(<ProfileModal />)
                    break
                default:
                    setTemplate(null)
            }
        }
    }, [name, props])

    return <Modal opened={!!name}>{template}</Modal>
}
