import { Record } from 'immutable'
import { LOAD, SUCCESS, FAIL } from './constants'

const McapRecord = Record({
    isLoading: false,
    price: null,
})

const reducer = (state = new McapRecord(), action) => {
    const { type, payload } = action

    switch (type) {
        case LOAD:
            return state.set('isLoading', true)

        case SUCCESS:
            return state.set('price', payload).set('isLoading', false)

        case FAIL:
            return state.set('isLoading', false)

        default:
            return state
    }
}

export default reducer
