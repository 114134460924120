import c from "./assets/sass/item.module.scss";

export const Item = ({ friend }) => {
	return (
		<li className={c.component}>
			<span className={c.name}>{friend.name || friend.telegramId}</span>
			<span className={c.eggs}>+{friend.earnedEggs}</span>
		</li>
	)
}
