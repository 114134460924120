import axios from 'core/http'

const root = 'https://www.prodiapp.ru/api/users'

export const userApi = {
    getUser: (telegramId) => axios.get(`${root}/${telegramId}`),
    createUser: (userData) => axios.post(`${root}/create`, userData),
    getBalance: (accountId) =>
        axios.get(
            `https://tonapi.io/v2/accounts/${accountId}/jettons/0%3A678dcf65e4f2741e753a6ae12caa9a3d37658c9e27d2b853026c02a2a536f32a?currencies=usd&supported_extensions=custom_payload`
        ),
    getFriends: (telegramId) => axios.get(`${root}/${telegramId}/friends`),
    requestWithdraw: (telegramId) => axios.put(`${root}/${telegramId}/requestWithdraw`),
    setProfile: (telegramId, name, profile) => axios.put(`${root}/${telegramId}/setProfile`, { name, profile }),
}
