import { LOAD, SUCCESS, FAIL } from './constants'
import { userApi } from 'api/user'

export const getFriends = (telegramId) => (dispatch) => {
    dispatch({ type: LOAD })

    userApi
        .getFriends(telegramId)
        .then((response) => {
            dispatch({
                type: SUCCESS,
                payload: response.data,
            })
        })
        .catch(() => {
            dispatch({ type: FAIL })
        })
}
