import { useState } from 'react'
import cn from 'classnames'
import { useWindowActions } from 'models/window'
import {useTonAddress, useTonConnectUI} from "@tonconnect/ui-react";
import {toast} from "react-toastify";

import { ReactComponent as CloseIcon } from './assets/images/close.svg'

import c from './assets/sass/disconnectModal.module.scss'

export const DisconnectModal = () => {
    const { closeWindow } = useWindowActions()
    const [isDisconnected, setIsDisconnected] = useState(false)
    const address = useTonAddress()
    const [tonConnectUI] = useTonConnectUI()

    const handleClickClose = () => {
        closeWindow()
    }

    const handleDisconnect = async () => {
        setIsDisconnected(true)

        try {
            await tonConnectUI.disconnect()
            toast.success('The wallet has been successful disconnected!')
        } catch (error) {
            toast.error('Some error occurs!')
        }
    }

    return (
        <div className={c.component}>
            <button className={c.close} onClick={handleClickClose}>
                <CloseIcon />
            </button>
            <div className={c.top}>
                <span className={c.title}>Disconnect</span>
                <span className={c.subtitle}>
                    You are going to disconnect your wallet
                </span>
            </div>
            <div className={c.form}>
                <div className={c.field}>
                    <span>{address ? address : 'No wallet found!'}</span>
                </div>
                <button
                    className={cn(c.button, { [c.disconnected]: isDisconnected })}
                    onClick={handleDisconnect}
                >
                    {isDisconnected ? 'Confirmed!' : 'Confirm'}
                </button>
            </div>
        </div>
    )
}
