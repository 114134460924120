import { createSelector } from 'reselect'
import { moduleName } from './constants'

const state = (state) => state[moduleName]

export const isLoadingSelector = createSelector(
    state,
    ({ isLoading }) => isLoading
)
export const priceSelector = createSelector(state, ({ price }) => price)
