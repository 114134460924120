import {List, Record} from 'immutable'
import { LOAD, SUCCESS, FAIL } from './constants'

const FriendsState = Record({
    name: null,
    telegramId: null,
    earnedEggs: null,
})

const FriendsRecord = Record({
    isLoading: false,
    friends: List(),
})

const reducer = (state = new FriendsRecord(), action) => {
    const { type, payload } = action

    switch (type) {
        case LOAD:
            return state.set('isLoading', true)

        case SUCCESS:
            return state
                .set('friends', List(payload.map(friend => new FriendsState(friend))))
                .set('isLoading', false)

        case FAIL:
            return state.set('isLoading', false)

        default:
            return state
    }
}

export default reducer
