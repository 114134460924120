import { useEffect, useState } from 'react'

import c from './assets/sass/empty.module.scss'

export const Empty = () => {
    const [maxRefHeight, setMaxRefHeight] = useState(0)

    useEffect(() => {
        const height = window.innerHeight - 250
        setMaxRefHeight(height)

        const handleResize = () => {
            const height = window.innerHeight - 250
            setMaxRefHeight(height)
        }

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <div className={c.component} style={{ height: `${maxRefHeight}px` }}>
            <div className={c.row}>
                <span>You have no friends. Send some invites first :)</span>
            </div>
        </div>
    )
}
