import { createSelector } from 'reselect'
import { moduleName } from './constants'

const state = (state) => state[moduleName]

export const isLoadingUserSelector = createSelector(
    state,
    ({ isLoadingUser }) => isLoadingUser
)
export const userSelector = createSelector(state, ({ user }) => user)
export const isBalanceLoadingSelector = createSelector(
    state,
    ({ isBalanceLoading }) => isBalanceLoading
)
export const balanceSelector = createSelector(state, ({ balance }) => balance)
