import { Record } from 'immutable'
import {
    USER_LOAD,
    USER_SUCCESS,
    USER_FAIL,
    BALANCE_LOAD,
    BALANCE_SUCCESS,
    BALANCE_FAIL,
} from './constants'

const UserState = Record({
    name: null,
    earnedEggs: null,
    telegramId: null,
    invitedBy: null,
    referrals: null,
    referralCount: null,
    profile: null,
    walletAddress: null,
    withdrawStatus: null,
    availableEggs: null,
})

const UserRecord = Record({
    isLoadingUser: false,
    user: new UserState(),
    isBalanceLoading: false,
    balance: null,
})

const reducer = (state = new UserRecord(), action) => {
    const { type, payload } = action

    switch (type) {
        case USER_LOAD:
            return state.set('isLoadingUser', true)

        case USER_SUCCESS:
            return state
                .set('user', new UserState(payload))
                .set('isLoadingUser', false)

        case USER_FAIL:
            return state.set('isLoadingUser', false)

        case BALANCE_LOAD:
            return state.set('isBalanceLoading', true)

        case BALANCE_SUCCESS:
            return state.set('balance', payload).set('isBalanceLoading', false)

        case BALANCE_FAIL:
            return state.set('isBalanceLoading', false)

        default:
            return state
    }
}

export default reducer
