import { configureStore } from '@reduxjs/toolkit'
import combinerReducer from 'reducer'

export default configureStore({
    reducer: combinerReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }),
})
