import c from './assets/sass/invite.module.scss'

export const Invite = () => {
    return (
        <div className={c.component}>
            <button className={c.button}>
                <span className={c.title}>Invite a Friend</span>
            </button>
            <span className={c.info}>
                For every friend you receive <strong>1 egg</strong>
            </span>
        </div>
    )
}
