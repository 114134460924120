import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
    openWindow as openWindowAC,
    closeWindow as closeWindowAC,
} from './actions'

export const useWindowActions = () => {
    const dispatch = useDispatch()

    const openWindow = useCallback(
        (name, props = null) => {
            dispatch(openWindowAC(name, props))
        },
        [dispatch]
    )

    const closeWindow = useCallback(() => {
        dispatch(closeWindowAC())
    }, [dispatch])

    return {
        openWindow,
        closeWindow,
    }
}
