import { Egg } from 'features/egg'
import { Mcap } from 'features/main/components/mcap'
import { Invite } from 'features/main/components/invite'
import { Navigation } from 'features/navigation'
import {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createUser, getUser } from 'models/user/actions'
import { userSelector } from 'models/user/selectors'

import c from './assets/sass/page.module.scss'

const MainPage = () => {
    const dispatch = useDispatch()
    const telegramData = window.Telegram.WebApp
    const userId = telegramData.initDataUnsafe?.user?.id
    const user = useSelector(userSelector)

    useEffect(() => {
        if (userId) {
            dispatch(getUser(userId))
        }
    }, [dispatch, userId])

    useEffect(() => {
        if (telegramData && user.telegramId !== userId) {
            const friend = window.Telegram.WebApp.initDataUnsafe.start_param

            const userData = {
                name: '',
                telegramId: userId,
                profile: '',
                walletAddress: '',
                invitedBy: friend !== userId ? friend : '',
            }

            dispatch(createUser(userData))
        }
    }, [dispatch, telegramData, user, userId])

    return (
        <div className={c.component}>
            <Mcap />
            <Egg />
            <div className={c.bottom}>
                <Invite />
                <Navigation />
            </div>
        </div>
    )
}

export default MainPage
