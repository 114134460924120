import { useDispatch } from 'react-redux'
import { openWindow } from 'models/window/actions'
import { modalNames } from 'features/modal/constants/names'

import c from './assets/sass/invite.module.scss'

export const Invite = () => {
    const dispatch = useDispatch()

    const openInvite = () => {
        dispatch(openWindow(modalNames.INVITE))
    }

    return (
        <div className={c.component}>
            <button className={c.button} onClick={openInvite}>
                <span className={c.title}>Invite a Friend</span>
            </button>
            <span className={c.info}>
                For every friend you receive <strong>1 egg</strong>
            </span>
        </div>
    )
}
