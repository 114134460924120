import windowReducer, { moduleName as windowModule } from 'models/window'
import mcapReducer, { moduleName as mcapModule } from 'models/mcap'
import userReducer, { moduleName as userModule } from 'models/user'
import friendsReducer, { moduleName as friendsModule } from 'models/friends'

const combinerReducer = {
    [windowModule]: windowReducer,
    [mcapModule]: mcapReducer,
    [userModule]: userReducer,
    [friendsModule]: friendsReducer,
}

export default combinerReducer
