export const moduleName = 'user'

export const USER_LOAD = `${moduleName}/USER_LOAD`
export const USER_SUCCESS = `${moduleName}/USER_SUCCESS`
export const USER_FAIL = `${moduleName}/USER_FAIL`

export const BALANCE_LOAD = `${moduleName}/BALANCE_LOAD`
export const BALANCE_SUCCESS = `${moduleName}/BALANCE_SUCCESS`
export const BALANCE_FAIL = `${moduleName}/BALANCE_FAIL`

export const CREATE_LOAD = `${moduleName}/CREATE_LOAD`
export const CREATE_SUCCESS = `${moduleName}/CREATE_SUCCESS`
export const CREATE_FAIL = `${moduleName}/CREATE_FAIL`

export const WITHDRAW_LOAD = `${moduleName}/WITHDRAW_LOAD`
export const WITHDRAW_SUCCESS = `${moduleName}/WITHDRAW_SUCCESS`
export const WITHDRAW_FAIL = `${moduleName}/WITHDRAW_FAIL`
